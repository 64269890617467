import axios from 'axios'
import store from '@tenant/core/init/store'
import router from '@tenant/core/router'
import { configApiHeaders } from '@tenant/utils/helper'

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    responseType: 'json',
})

instance.interceptors.request.use(
    async (config) => {
        config.headers = configApiHeaders(config.headers)

        return config
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    (resp) => {
        return resp.data
    },
    async (err) => {
        switch (err?.response?.status) {
            case 401:
                await Promise.all([
                    store.dispatch('logout'),
                    router.push({ name: 'auth.login' }),
                ])

                return Promise.reject(err)
            case 406:
                await router.push({ name: 'subscriptions.change' })

                return Promise.reject(err)
            default:
                break
        }

        return Promise.reject(err)
    }
)

export default instance
